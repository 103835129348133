.home--parallax--head {
  height: 90vh;
  filter: grayscale(.8);
  -webkit-filter: grayscale(.8);
}

.home--bloc--middle {
  margin: 10px 25px 10px 25px;
}

.to--top--button {
  color: #441c2a !important;
}

.home--text--inter {
  font-family: 'Lobster', cursive;
  color: #441c2a;
}

.home--image--interbloc {
  height: 150px;
}
.home--public--button{
  background-color: #428bca !important;
  color: white !important;
}